import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './admin/templ-admin/dashboard/dashboard.component';
import { DistributerComponent } from './admin/templ-admin/distributer/distributer.component';
import { InstallersComponent } from './admin/templ-admin/installers/installers.component';
import { ProfilAdminComponent } from './admin/templ-admin/profil-admin/profil-admin.component';
import { TemplAdminComponent } from './admin/templ-admin/templ-admin.component';
import { AddTestComponent } from './admin/templ-admin/tests/add-test/add-test.component';
import { DetailsTestComponent } from './admin/templ-admin/tests/details-test/details-test.component';
import { TestsComponent } from './admin/templ-admin/tests/tests.component';
import { UpdateTestComponent } from './admin/templ-admin/tests/update-test/update-test.component';
import { AddTrainingComponent } from './admin/templ-admin/trainings-admin/add-training/add-training.component';
import { TrainingsAdminComponent } from './admin/templ-admin/trainings-admin/trainings-admin.component';
import { UpdateTrainingComponent } from './admin/templ-admin/trainings-admin/update-training/update-training.component';
import { ProfilDistributerComponent } from './distributer/templ-distributer/profil-distributer/profil-distributer.component';
import { TemplDistributerComponent } from './distributer/templ-distributer/templ-distributer.component';
import { TrainingsDistribiteurComponent } from './distributer/templ-distributer/trainings-distribiteur/trainings-distribiteur.component';
import { ProfilComponent } from './installer/templ-installer/profil/profil.component';
import { TemplInstallerComponent } from './installer/templ-installer/templ-installer.component';
import { DetailsTrainingComponent } from './installer/templ-installer/trainings/details-training/details-training.component';
import { TrainingsComponent } from './installer/templ-installer/trainings/trainings.component';
import { AuthGuard } from './_guards/auth.guard';
import { DetailsCertificateComponent } from './components/details-certificate/details-certificate.component';
import { CertificatesInstallerComponent } from './installer/templ-installer/certificates-installer/certificates-installer.component';
import { LoginGuard } from './_guards/login.guard';
import { InstallersByDistrubuterComponent } from './distributer/templ-distributer/installers-by-distrubuter/installers-by-distrubuter.component';
import { ShareTrainingComponent } from './admin/templ-admin/trainings-admin/share-training/share-training.component';
import { DetailsComponent } from './admin/templ-admin/trainings-admin/details/details.component';
import { DetailsTrainingDistributerComponent } from './distributer/templ-distributer/trainings-distribiteur/details-training-distributer/details-training-distributer.component';
import { ShareTrainingDistributerComponent } from './distributer/templ-distributer/trainings-distribiteur/share-training-distributer/share-training-distributer.component';
import { AdminGuard } from './_guards/admin.guard';
import { InstallerGuard } from './_guards/installer.guard';
import { DistrubuterGuard } from './_guards/distrubuter.guard';
import { ListCertificatesComponent } from './admin/templ-admin/installers/list-certificates/list-certificates.component';
import { TestsInstallerComponent } from './installer/templ-installer/tests-installer/tests-installer.component';
import { TestsDistributerComponent } from './distributer/templ-distributer/tests-distributer/tests-distributer.component';
import { CertificatesDistributerComponent } from './distributer/templ-distributer/certificates-distributer/certificates-distributer.component';
import { ListCeritficatesInsatllersByDistributerComponent } from './distributer/templ-distributer/installers-by-distrubuter/list-ceritficates-insatllers-by-distributer/list-ceritficates-insatllers-by-distributer.component';
import { ListCertificatesDistributeursComponent } from './admin/templ-admin/distributer/list-certificates-distributeurs/list-certificates-distributeurs.component';
import { DashbordInstallateurComponent } from './installer/templ-installer/dashbord-installateur/dashbord-installateur.component';
import { DashboardDistributerComponent } from './distributer/templ-distributer/dashboard-distributer/dashboard-distributer.component';
import { AuhtoriseComponent } from './componets/auhtorise/auhtorise.component';
import { CallbackComponent } from './componets/callback/callback.component';
import { LoginComponent } from './components/login/login.component';


const routes: Routes = [
  
  { path: '', redirectTo: '/se_connecter', pathMatch: 'full' , canActivate :[LoginGuard] },
  { path: 'se_connecter', component: LoginComponent , canActivate :[LoginGuard] },
  { path: 'connexion', component: AuhtoriseComponent  },
  { path: 'callback', component: CallbackComponent },
  { path: 'certificat/:id', component: DetailsCertificateComponent  , canActivate :[AuthGuard], },
  { path: 'admin', component: TemplAdminComponent , canActivate :[AuthGuard,AdminGuard],
  children: [
    { path: '', component: DashboardComponent },
    { path: 'profil', component: ProfilAdminComponent },
    { path: 'installateurs', component: InstallersComponent },
    { path: 'installateurs/liste-certificats/:id', component: ListCertificatesComponent },
    { path: 'distributeurs', component: DistributerComponent },
    { path: 'distributeurs/liste-certificats/:id', component: ListCertificatesDistributeursComponent },
    { path: 'formations', component: TrainingsAdminComponent},
    { path: 'formations/ajout', component: AddTrainingComponent },
    { path: 'formations/modification/:id', component: UpdateTrainingComponent },
    { path: 'formations/details/:id', component: DetailsComponent },
    { path: 'formations/partage/:id', component: ShareTrainingComponent },
    { path: 'questionnaires', component: TestsComponent },
    { path: 'questionnaires/ajout/:id', component: AddTestComponent },
    { path: 'questionnaires/modification/:id', component: UpdateTestComponent },
    { path: 'tests/details/:id', component: DetailsTestComponent },
  ]
  },
  { path: 'installateur', component: TemplInstallerComponent , canActivate :[AuthGuard,InstallerGuard],
  children: [
    { path: '', component: DashbordInstallateurComponent },
    { path: 'formations', component: TrainingsComponent  },
    { path: 'formations/details/:id', component: DetailsTrainingComponent },
    { path: 'profil', component: ProfilComponent  },
    { path: 'test/:id', component: TestsInstallerComponent  },
    { path: 'certificats', component: CertificatesInstallerComponent  },
  ]
  },
  { path: 'distributeur', component: TemplDistributerComponent, canActivate :[AuthGuard,DistrubuterGuard],
  children: [
    { path: '', component: DashboardDistributerComponent },
    { path: 'installateurs', component: InstallersByDistrubuterComponent  },
    { path: 'installateurs/liste-certificats/:id', component: ListCeritficatesInsatllersByDistributerComponent  },
    { path: 'formations', component: TrainingsDistribiteurComponent  },
    { path: 'formations/details/:id', component: DetailsTrainingDistributerComponent },
    { path: 'formations/partage/:id', component: ShareTrainingDistributerComponent },
    { path: 'profil', component: ProfilDistributerComponent  },
    { path: 'test/:id', component: TestsDistributerComponent  },
    { path: 'certificats', component: CertificatesDistributerComponent  },
    
  ]
  },
 

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
